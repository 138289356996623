@import "https://fonts.googleapis.com/css2?family=Manrope:wght@300;400;500;600;700;800&display=swap";
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  -webkit-text-size-adjust: 100%;
  line-height: 1.15;
}

body {
  margin: 0;
}

main {
  display: block;
}

h1 {
  margin: .67em 0;
  font-size: 2em;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

pre {
  font-family: monospace;
  font-size: 1em;
}

a {
  background-color: #0000;
}

abbr[title] {
  border-bottom: none;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
}

b, strong {
  font-weight: bolder;
}

code, kbd, samp {
  font-family: monospace;
  font-size: 1em;
}

small {
  font-size: 80%;
}

sub, sup {
  vertical-align: baseline;
  font-size: 75%;
  line-height: 0;
  position: relative;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

img {
  border-style: none;
}

button, input, optgroup, select, textarea {
  margin: 0;
  font-family: inherit;
  font-size: 100%;
  line-height: 1.15;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

button, [type="button"], [type="reset"], [type="submit"] {
  -webkit-appearance: button;
}

button::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="button"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="reset"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

[type="submit"]::-moz-focus-inner {
  border-style: none;
  padding: 0;
}

button:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="button"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="reset"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

[type="submit"]:-moz-focusring {
  outline: 1px dotted ButtonText;
}

fieldset {
  padding: .35em .75em .625em;
}

legend {
  box-sizing: border-box;
  color: inherit;
  white-space: normal;
  max-width: 100%;
  padding: 0;
  display: table;
}

progress {
  vertical-align: baseline;
}

textarea {
  overflow: auto;
}

[type="checkbox"], [type="radio"] {
  box-sizing: border-box;
  padding: 0;
}

:is([type="number"]::-webkit-inner-spin-button, [type="number"]::-webkit-outer-spin-button) {
  height: auto;
}

[type="search"] {
  -webkit-appearance: textfield;
  outline-offset: -2px;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  -webkit-appearance: button;
  font: inherit;
}

details {
  display: block;
}

summary {
  display: list-item;
}

template, [hidden] {
  display: none;
}

body {
  background-color: #010101;
  font-family: Manrope, sans-serif;
}

.g1, .g2 {
  display: none !important;
}

.caption-line {
  color: #fff;
  letter-spacing: -1.2px;
  align-items: center;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
  display: flex;
}

.caption-line:before {
  content: "";
  background-color: #00f3bc;
  border-radius: 2px;
  width: 2px;
  height: 30px;
  margin-right: 20px;
  display: inline-block;
}

@media screen and (min-width: 768px) {
  .caption-line {
    letter-spacing: -.9px;
    font-size: 18px;
  }
}

@media screen and (min-width: 1004px) {
  .caption-line {
    letter-spacing: -1.2px;
    font-size: 24px;
  }
}

.overflowed {
  overflow: hidden;
}

.offices {
  color: #fff;
  text-align: left;
  border: none;
  flex-direction: column;
  padding-top: 10px;
  font-family: Manrope;
  text-decoration: none;
  display: flex;
}

.offices > span {
  margin-top: 10px;
  -webkit-text-decoration: none;
  text-decoration: none;
}

.header {
  z-index: 1000;
  background-color: #000;
  justify-content: space-between;
  align-items: center;
  height: 63px;
  padding: 0 20px;
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
}

@media screen and (min-width: 768px) {
  .header {
    -webkit-backdrop-filter: blur(15px);
    backdrop-filter: blur(15px);
    background: #00000080;
    padding: 0 30px;
  }
}

@media screen and (min-width: 1200px) {
  .header {
    background: #0006;
    height: 85px;
    padding: 0 calc(50% - 555px);
  }
}

@media screen and (min-width: 1004px) {
  .header__logo img {
    height: 50px;
  }
}

.nav {
  align-items: center;
  gap: 30px;
  display: none;
}

.nav__link {
  color: #fff;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 400;
  line-height: 120%;
  text-decoration: none;
}

.nav .header-cta {
  margin-top: unset;
}

.nav__options {
  align-items: center;
  gap: 30px;
  margin-left: 90px;
  display: flex;
}

.nav__langs {
  gap: 15px;
  display: flex;
}

.nav__lang {
  color: #fff;
  font-family: Manrope;
  font-size: 18px;
  line-height: 120%;
  text-decoration: none;
}

@media screen and (min-width: 1004px) {
  .nav {
    display: inline-flex;
  }
}

.nav-burger {
  background: unset;
  border: 0;
}

.nav-burger svg {
  fill: #fff;
  transition: all .3s linear;
}

.nav-burger.active svg {
  fill: #00f3bc;
}

@media screen and (min-width: 1004px) {
  .nav-burger {
    display: none;
  }
}

.video {
  justify-content: center;
  align-items: center;
  width: 100%;
  height: calc(100vh - 60px);
  padding: 0 20px;
  display: flex;
  position: relative;
  overflow: hidden;
}

.video:after {
  content: "";
  z-index: 10;
  background: linear-gradient(#0000 0%, #000 50.73%);
  width: 100%;
  height: 300px;
  display: block;
  position: absolute;
  bottom: -1px;
  left: 0;
}

.video__content {
  z-index: 20;
  padding: 0 20px;
  font-size: 50px;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 768px) {
  .video__content {
    padding: 0 30px;
  }
}

@media screen and (min-width: 1004px) {
  .video__content {
    max-width: 1110px;
    margin: 0 auto;
    padding: 0;
    bottom: 60px;
    left: 0;
    right: 0;
  }
}

.video video {
  z-index: 1;
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
}

.video__caption {
  color: #fff;
  letter-spacing: -1.3px;
  font-size: 26px;
  font-weight: 700;
  line-height: 120%;
}

@media screen and (min-width: 768px) {
  .video__caption {
    max-width: 585px;
    margin-bottom: 20px;
    font-size: 40px;
  }
}

@media screen and (min-width: 1004px) {
  .video__caption {
    letter-spacing: -2.8px;
    min-width: 820px;
    font-size: 56px;
  }
}

.video__text {
  color: #c8c8c8;
  font-size: 18px;
  line-height: 120%;
}

@media screen and (min-width: 768px) {
  .video__text {
    max-width: 560px;
    font-size: 24px;
  }
}

@media screen and (min-width: 1004px) {
  .video__text {
    min-width: 865px;
    font-size: 24px;
  }
}

.header-cta {
  white-space: nowrap;
  color: #c8c8c8;
  background-color: unset;
  text-align: center;
  border-radius: 35px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  padding-top: 2px;
  padding-bottom: 2px;
  font-family: Manrope;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  display: inline-flex;
  position: relative;
  overflow: hidden;
}

.header-cta:before {
  content: "";
  background-image: conic-gradient(#0000, #0000, #0000, #00f3bc);
  width: 200%;
  height: 500px;
  animation: 4s linear infinite cta-anima;
  position: absolute;
}

.header-cta:hover {
  color: #fff;
  background-color: #1a1a1a;
}

.header-cta span {
  z-index: 1;
  background-color: #000;
  border-radius: 35px;
  width: calc(100% - 2px);
  padding: 20px 0;
  position: relative;
}

@media screen and (min-width: 768px) {
  .header-cta span {
    padding: 20px 50px;
  }
}

@media screen and (min-width: 1004px) {
  .header-cta span {
    padding: 14px 50px;
  }
}

@media screen and (min-width: 768px) {
  .header-cta {
    width: unset;
    padding-top: 2px;
    padding-bottom: 2px;
  }
}

.mobile-nav {
  z-index: 1000;
  visibility: hidden;
  opacity: 0;
  background-color: #0d0d0d;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: calc(100vh - 63px);
  padding: 0 20px;
  transition: all .3s linear;
  display: flex;
  position: absolute;
  top: 100%;
  left: 0;
}

.mobile-nav.active {
  visibility: visible;
  opacity: 1;
}

.mobile-nav__list {
  flex-direction: column;
  align-items: center;
  gap: 10px;
  margin: 30px 0;
  list-style-type: none;
  display: flex;
}

.mobile-nav__item {
  padding: 5px 15px;
}

.mobile-nav__link {
  color: #fff;
  font-family: Manrope;
  font-size: 18px;
  line-height: 120%;
  text-decoration: none;
}

.mobile-nav__langs {
  justify-content: center;
  gap: 20px;
  margin-top: 30px;
  display: flex;
}

.mobile-nav__lang {
  color: #fff;
  font-family: Manrope;
  font-size: 18px;
  line-height: 120%;
  text-decoration: none;
}

.mobile-nav__lang.active {
  font-weight: 600;
}

.mobile-nav__contacts {
  text-align: center;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
  display: flex;
}

.mobile-nav__contacts-title {
  color: #fff;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 600;
}

.mobile-nav__contacts-email {
  color: #c8c8c8;
  font-family: Manrope;
  font-size: 18px;
  line-height: 120%;
  text-decoration: none;
}

@media screen and (min-width: 768px) {
  .mobile-nav .header-cta {
    margin: 0 auto;
    display: flex;
  }
}

@keyframes cta-anima {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.map {
  padding: 120px 20px 0;
}

@media screen and (min-width: 768px) {
  .map {
    padding: 230px 30px 130px;
  }
}

@media screen and (min-width: 1004px) {
  .map {
    max-width: 1110px;
    margin: 0 auto;
    padding-bottom: 200px;
    padding-left: 0;
    padding-right: 0;
  }
}

.map__caption {
  color: #fff;
  letter-spacing: -1.2px;
  margin-bottom: 20px;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
}

@media screen and (min-width: 768px) {
  .map__caption {
    max-width: 560px;
    font-size: 24px;
  }
}

@media screen and (min-width: 1004px) {
  .map__caption {
    letter-spacing: -1.8px;
    max-width: 850px;
    font-size: 36px;
  }
}

.map__caption-sub-text {
  color: #c8c8c8;
  letter-spacing: -.8px;
  max-width: 560px;
  margin-bottom: 50px;
  font-family: Manrope;
  font-size: 16px;
  line-height: 127.5%;
}

.map__content {
  background: #000;
  border: 1px solid #222;
  border-radius: 20px;
  flex-direction: column;
  align-items: center;
  padding: 42px 22px 0;
  display: flex;
  overflow: hidden;
  box-shadow: 20px 20px 35px #2223;
}

@media screen and (min-width: 768px) {
  .map__content {
    flex-direction: row;
    padding-bottom: 42px;
    padding-left: 42px;
  }
}

@media screen and (min-width: 1004px) {
  .map__content {
    height: 485px;
    padding-left: 60px;
  }
}

.map__info {
  flex-direction: column;
  align-items: center;
  display: flex;
}

@media screen and (min-width: 768px) {
  .map__info {
    align-items: flex-start;
  }
}

.map__title {
  color: #00f3bc;
  text-align: center;
  font-family: Manrope;
  font-size: 36px;
  font-weight: 700;
}

@media screen and (min-width: 768px) {
  .map__title {
    text-align: left;
    font-size: 28px;
  }
}

.map__text {
  color: #fff;
  text-align: center;
  margin: 20px 0 10px;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  line-height: 120%;
}

@media screen and (min-width: 768px) {
  .map__text {
    text-align: left;
    max-width: 440px;
    font-weight: 400;
  }
}

@media screen and (min-width: 1004px) {
  .map__text {
    letter-spacing: -1.2px;
    max-width: 600px;
    font-size: 24px;
    font-weight: 500;
  }
}

.map__sub-text {
  color: #c8c8c8;
  letter-spacing: -.8px;
  font-family: Manrope;
  font-size: 16px;
  line-height: 127.5%;
}

@media screen and (min-width: 1004px) {
  .map__sub-text {
    font-size: 18px;
  }
}

.map__video {
  justify-content: center;
  width: 100%;
  height: 310px;
  display: flex;
  position: relative;
  overflow: hidden;
}

@media screen and (min-width: 1004px) {
  .map__video {
    height: 380px;
  }
}

@media screen and (max-width: 440px) {
  .map__video {
    height: 210px;
  }
}

.map video {
  height: 130%;
  position: absolute;
  bottom: -100px;
}

@media screen and (min-width: 768px) {
  .map video {
    position: relative;
    bottom: 0;
    transform: scale(1.21);
  }
}

@media screen and (min-width: 1004px) {
  .map video {
    height: 380px;
    left: 40px;
  }
}

.solution {
  padding: 100px 20px;
}

@media screen and (min-width: 768px) {
  .solution {
    padding: 0 30px 130px;
  }
}

@media screen and (min-width: 1004px) {
  .solution {
    max-width: 1110px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.solution__caption {
  margin-bottom: 30px;
}

@media screen and (min-width: 1004px) {
  .solution__caption {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 768px) {
  .solution__content {
    flex-wrap: wrap;
    gap: 30px;
    display: flex;
  }
}

@media screen and (min-width: 1004px) {
  .solution__content {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "a a b"
                         "c c b"
                         "d e e"
                         "d g g";
    display: grid;
  }
}

@media screen and (min-width: 768px) {
  .solution__content2 {
    flex-wrap: wrap;
    gap: 30px;
    display: flex;
  }
}

@media screen and (min-width: 1004px) {
  .solution__content2 {
    grid-template: "a b b" 2fr
    / 1fr 1fr 1fr;
    display: grid;
  }
}

@media screen and (min-width: 768px) {
  .solution__content3 {
    flex-wrap: wrap;
    gap: 30px;
    display: flex;
  }
}

@media screen and (min-width: 1004px) {
  .solution__content3 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "a a b"
                         "c c b"
                         "d e e"
                         "d e e";
    display: grid;
  }
}

.marsolution {
  padding: 100px 20px;
}

@media screen and (min-width: 768px) {
  .marsolution {
    padding: 0 30px 130px;
  }
}

@media screen and (min-width: 1004px) {
  .marsolution {
    max-width: 1110px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.marsolution__caption {
  margin-bottom: 30px;
}

@media screen and (min-width: 1004px) {
  .marsolution__caption {
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 768px) {
  .marsolution__content {
    flex-wrap: wrap;
    gap: 30px;
    display: flex;
  }
}

@media screen and (min-width: 1004px) {
  .marsolution__content {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "a b b";
    display: grid;
  }
}

@media screen and (min-width: 768px) {
  .marsolution__content2 {
    flex-wrap: wrap;
    gap: 30px;
    display: flex;
  }
}

@media screen and (min-width: 1004px) {
  .marsolution__content2 {
    grid-template: "a b b" 2fr
    / 1fr 1fr 1fr;
    display: grid;
  }
}

@media screen and (min-width: 768px) {
  .marsolution__content3 {
    flex-wrap: wrap;
    gap: 30px;
    display: flex;
  }
}

@media screen and (min-width: 1004px) {
  .marsolution__content3 {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "a a b"
                         "c c b"
                         "d e e"
                         "d e e";
    display: grid;
  }
}

@media screen and (min-width: 768px) {
  .tech-card {
    width: calc(50% - 15px);
  }
}

@media screen and (min-width: 1004px) {
  .tech-card {
    background: #0d0d0d;
    border: 2px solid #222;
    border-radius: 20px;
    width: 100%;
  }
}

@media screen and (max-width: 1004px) {
  .tech-card {
    transform: none !important;
  }

  .tech-card .js-tilt-glare {
    display: none !important;
  }
}

.tech-card + .tech-card {
  margin-top: 30px;
}

@media screen and (min-width: 768px) {
  .tech-card + .tech-card {
    margin-top: unset;
  }
}

.tech-card__next-line {
  display: none;
}

@media screen and (min-width: 1004px) {
  .tech-card__next-line {
    display: block;
  }
}

.tech-card__tag {
  color: #c8c8c8;
  letter-spacing: -.6px;
  opacity: .5;
  border: 1px solid #c8c8c8;
  border-radius: 65px;
  padding: 6px 26px;
  font-family: Manrope;
  font-size: 12px;
  line-height: 15px;
  display: none;
}

@media screen and (min-width: 1004px) {
  .tech-card__tag {
    display: inline-block;
  }
}

.tech-card__img {
  width: 100%;
}

.tech-card__title {
  color: #fff;
  letter-spacing: -1.2px;
  margin: 20px 0 10px;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;
}

.tech-card__text {
  color: #b2b2b2;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 400;
  line-height: 120%;
}

.tech-card_11, .tech-card_1 {
  grid-area: a;
}

.tech-card_21, .tech-card_2 {
  grid-area: b;
}

.tech-card_3 {
  grid-area: c;
}

.tech-card_4 {
  grid-area: e;
}

.tech-card_6 {
  grid-area: d;
}

.tech-card_8 {
  grid-area: g;
}

@media screen and (min-width: 1004px) {
  .tech-card_11 {
    flex-direction: column-reverse;
    justify-content: space-around;
    gap: 17px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
  }

  .tech-card_11 picture {
    border-radius: 10px;
    flex-shrink: 0;
    justify-content: center;
    display: flex;
    overflow: hidden;
  }

  .tech-card_11 picture img {
    width: 203.535px;
    height: 195px;
  }

  .tech-card_1, .tech-card_3 {
    flex-direction: row-reverse;
    align-items: center;
    gap: 17px;
    height: 270px;
    padding-left: 40px;
    display: flex;
  }

  .tech-card_1 picture, .tech-card_3 picture {
    border-radius: 10px;
    flex-shrink: 0;
    overflow: hidden;
  }

  .tech-card_2 {
    flex-direction: column-reverse;
    justify-content: space-between;
    display: flex;
  }

  .tech-card_2 .tech-card__content {
    padding: 40px 40px 0;
  }

  .tech-card_21 {
    flex-direction: column-reverse;
    justify-content: space-between;
    padding: 40px 40px 0;
    display: flex;
  }

  .tech-card_4 {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .tech-card_4 picture {
    width: 240px;
  }

  .tech-card_4 .tech-card__content {
    padding: 40px 40px 0;
  }

  .tech-card_5 {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .tech-card_5 .tech-card__content {
    padding: 40px 40px 0;
  }

  .tech-card_5 picture {
    width: 500px;
    margin-bottom: 60px;
  }

  .tech-card_6 {
    flex-direction: column-reverse;
    justify-content: space-between;
    align-items: center;
    display: flex;
  }

  .tech-card_6 .tech-card__content {
    padding: 40px 40px 0;
  }

  .tech-card_6 picture {
    width: 380px;
    transform: translateY(4px);
  }

  .tech-card_7 {
    flex-direction: row-reverse;
    display: flex;
  }

  .tech-card_7 picture {
    flex-shrink: 0;
    align-self: center;
    margin-left: 50px;
    margin-right: 65px;
  }

  .tech-card_7 .tech-card__content {
    padding-top: 40px;
    padding-left: 40px;
  }

  .tech-card_8 {
    flex-direction: row-reverse;
    display: flex;
  }

  .tech-card_8 picture {
    flex-shrink: 0;
    align-self: center;
    margin-right: 40px;
  }

  .tech-card_8 .tech-card__content {
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    padding: 40px;
    display: flex;
  }
}

.marsolution .tech-card_1 {
  height: initial;
  padding-left: 0;
}

@media screen and (min-width: 1004px) {
  .marsolution .tech-card_1 {
    flex-direction: column-reverse;
    justify-content: start;
    align-items: center;
    display: flex;
  }

  .marsolution .tech-card_1 .tech-card__content {
    padding: 40px 40px 0;
  }

  .marsolution .tech-card_1 picture {
    width: 200px;
    padding-top: 20%;
  }
}

.marsolution .tech-card_2 {
  height: initial;
  padding-left: 0;
}

@media screen and (min-width: 1004px) {
  .marsolution .tech-card_2 picture {
    padding: 60px;
  }
}

.plans {
  padding: 0 20px;
}

.plans__cta {
  color: #6d6d6d;
  letter-spacing: -.9px;
  background: #00000080;
  border: 1px solid #6d6d6d;
  border-radius: 73px;
  margin-top: 30px;
  margin-left: auto;
  padding: 18px 32px;
  font-family: Manrope;
  font-size: 18px;
  line-height: 24px;
  display: none;
}

@media screen and (min-width: 768px) {
  .plans__cta {
    display: flex;
  }
}

.plans-xs {
  margin-top: 30px;
}

@media screen and (min-width: 768px) {
  .plans-xs {
    display: none;
  }
}

.plans-md {
  background: #0d0d0d;
  border: 1px solid #6d6d6d;
  border-radius: 20px;
  margin-top: 30px;
  padding: 30px;
  display: none;
}

@media screen and (min-width: 768px) {
  .plans-md {
    display: flex;
  }
}

.plans-md__col_centered {
  flex-direction: column;
  align-items: center;
  margin-left: 48px;
  display: flex;
}

.plans-md__caption {
  color: #fff;
  letter-spacing: -1.2px;
  margin-bottom: 20px;
  padding: 19px 0;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
}

.plans-md__item {
  color: #c8c8c8;
  padding: 19px 0;
  font-family: Manrope;
  font-size: 18px;
  line-height: 120%;
}

.plans-md__icon {
  justify-content: center;
  align-items: center;
  height: 60px;
  display: flex;
}

.plans-md__icon_l {
  height: 81px;
}

.plan-xs {
  background-color: unset;
  color: #c8c8c8;
  letter-spacing: -.9px;
  border: 1px solid #222;
  border-radius: 20px;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  padding: 10px 20px;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 500;
  line-height: 17.872px;
  display: flex;
}

.plan-xs-view {
  visibility: hidden;
  opacity: 0;
  background: #0d0d0d;
  border: 1px solid #222;
  border-radius: 10px;
  height: 0;
}

.plan-xs-view.active {
  opacity: 1;
  visibility: visible;
  height: auto;
  margin: 10px 0;
  padding: 30px 20px;
}

.plan-xs-view__item {
  color: #c8c8c8;
  align-items: center;
  gap: 19px;
  height: 60px;
  padding-left: 15px;
  font-family: Manrope;
  font-size: 18px;
  line-height: 120%;
  display: flex;
}

.plan-xs-view__item svg {
  flex-shrink: 0;
}

.plan-xs-view__btn {
  color: #00f3bc;
  letter-spacing: -.9px;
  background: #00000080;
  border: 1px solid #00f3bc;
  border-radius: 73px;
  width: 100%;
  margin-top: 30px;
  padding: 18px 32px;
  font-family: Manrope;
  font-size: 18px;
  line-height: 24px;
}

.limits {
  padding: 100px 20px;
}

@media screen and (min-width: 768px) {
  .limits {
    flex-direction: column;
    align-items: center;
    padding: 130px 0;
    display: flex;
  }
}

.limits svg {
  display: none;
}

@media screen and (min-width: 768px) {
  .limits svg {
    display: block;
    transform: translateY(30px);
  }
}

@media screen and (min-width: 1004px) {
  .limits svg {
    width: 100%;
    transform: translateY(90px);
  }
}

.limits__caption {
  text-align: center;
  color: #fff;
  letter-spacing: -1.8px;
  margin-bottom: 20px;
  font-family: Manrope;
  font-size: 36px;
  font-weight: 700;
  line-height: 130%;
}

@media screen and (min-width: 768px) {
  .limits__caption {
    font-size: 74px;
    line-height: 120%;
  }
}

@media screen and (min-width: 1004px) {
  .limits__caption {
    letter-spacing: -6px;
    font-size: 120px;
    line-height: 120%;
  }
}

.limits__text {
  color: #c8c8c8;
  text-align: center;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 400;
  line-height: 120%;
}

@media screen and (min-width: 768px) {
  .limits__text {
    max-width: 440px;
    font-size: 16px;
  }
}

@media screen and (min-width: 1004px) {
  .limits__text {
    max-width: 650px;
    font-size: 24px;
  }
}

.glide {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}

.glide * {
  box-sizing: inherit;
}

.glide__track {
  overflow: hidden;
}

.glide__slides {
  backface-visibility: hidden;
  transform-style: preserve-3d;
  touch-action: pan-Y;
  white-space: nowrap;
  will-change: transform;
  flex-wrap: nowrap;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  position: relative;
  overflow: hidden;
}

.glide__slides--dragging {
  -webkit-user-select: none;
  user-select: none;
}

.glide__slide {
  white-space: normal;
  -webkit-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
}

.glide__slide a {
  -webkit-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
}

.glide__arrows, .glide__bullets {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.glide--rtl {
  direction: rtl;
}

.glide__arrow {
  z-index: 2;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 0 .25em .5em #0000001a;
  opacity: 1;
  cursor: pointer;
  background-color: #0000;
  border: 2px solid #ffffff80;
  border-radius: 4px;
  padding: 9px 12px;
  line-height: 1;
  transition: opacity .15s, border .3s ease-in-out;
  display: block;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__arrow:focus {
  outline: none;
}

.glide__arrow:hover {
  border-color: #fff;
}

.glide__arrow--left {
  left: 2em;
}

.glide__arrow--right {
  right: 2em;
}

.glide__arrow--disabled {
  opacity: .33;
}

.glide__bullets {
  z-index: 2;
  list-style: none;
  display: inline-flex;
  position: absolute;
  bottom: 2em;
  left: 50%;
  transform: translateX(-50%);
}

.glide__bullet {
  cursor: pointer;
  background-color: #ffffff80;
  border: 2px solid #0000;
  border-radius: 50%;
  width: 9px;
  height: 9px;
  margin: 0 .25em;
  padding: 0;
  line-height: 0;
  transition: all .3s ease-in-out;
  box-shadow: 0 .25em .5em #0000001a;
}

.glide__bullet:focus {
  outline: none;
}

.glide__bullet:hover, .glide__bullet:focus {
  background-color: #ffffff80;
  border: 2px solid #fff;
}

.glide--swipeable {
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;
}

.glide--dragging {
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}

.slider {
  padding: 100px 20px;
}

@media screen and (min-width: 768px) {
  .slider {
    padding: 0 30px 130px;
  }
}

@media screen and (min-width: 1004px) {
  .slider {
    max-width: 1110px;
    margin: 0 auto;
    padding: 200px 0;
  }
}

.slider .glide__slide:hover {
  z-index: 100;
}

.slider .glide__slide:before {
  content: "";
  z-index: 100;
  background: linear-gradient(#0d0d0d 0%, #0d0d0d00 100%);
  width: 100%;
  height: 25%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

@media screen and (min-width: 768px) {
  .slider .glide__slide:before {
    z-index: 99;
    background: linear-gradient(90deg, #0d0d0d 60%, #0d0d0d00 100%);
    width: 70%;
    height: 100%;
    top: 0;
    left: -20%;
  }
}

.slider__info {
  z-index: 105;
  position: relative;
}

.slider__caption {
  color: #fff;
  letter-spacing: -1.2px;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
}

@media screen and (min-width: 1004px) {
  .slider__caption {
    letter-spacing: -1.8px;
    font-size: 36px;
  }
}

.slider__title {
  color: #fff;
  text-align: center;
  letter-spacing: -1.2px;
  margin-bottom: 20px;
  padding: 0 30px;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  line-height: 120%;
}

@media screen and (min-width: 768px) {
  .slider__title {
    text-align: left;
    max-width: 300px;
  }
}

@media screen and (min-width: 1004px) {
  .slider__title {
    letter-spacing: -1.8px;
    max-width: 470px;
    padding: 0 0 0 65px;
    font-size: 36px;
  }
}

.slider__text {
  color: #c8c8c8;
  text-align: center;
  letter-spacing: -.9px;
  padding: 0 30px;
  font-family: Manrope;
  font-size: 18px;
  line-height: 120%;
}

@media screen and (min-width: 768px) {
  .slider__text {
    text-align: left;
    max-width: 300px;
  }
}

@media screen and (min-width: 1004px) {
  .slider__text {
    max-width: 470px;
    padding: 0 0 0 65px;
    font-size: 18px;
  }
}

.slider__sub {
  color: #6d6d6d;
  margin-top: 20px;
  padding: 0 30px;
  font-family: Manrope;
  font-size: 18px;
  line-height: 120%;
  display: block;
}

@media screen and (min-width: 1004px) {
  .slider__sub {
    padding: 0 0 0 65px;
  }
}

.slider__video {
  margin-top: 20px;
  position: relative;
}

@media screen and (min-width: 768px) {
  .slider__video {
    width: 90%;
    height: 100%;
    margin-top: 0;
    position: absolute;
    top: 0;
    right: 0;
  }
}

.slider video {
  opacity: .99;
  width: 100%;
  position: absolute;
  right: 0;
  transform: translateY(4px);
}

@media screen and (min-width: 768px) {
  .slider video {
    transform: unset;
    width: auto;
    height: 100%;
  }
}

.glide {
  margin: 30px 0;
}

.glide__slide {
  background: #0d0d0d;
  border: 1px solid #6d6d6d;
  border-radius: 20px;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  min-height: 100%;
  padding-top: 30px;
  display: flex;
  overflow: hidden;
  box-shadow: 20px 20px 35px #2223;
}

@media screen and (min-width: 768px) {
  .glide__slide {
    flex-direction: row;
    padding-top: 60px;
    padding-bottom: 80px;
    position: relative;
  }
}

@media screen and (min-width: 1004px) {
  .glide__slide {
    padding-top: 140px;
    padding-bottom: 140px;
  }
}

.glide__bullets {
  bottom: -30px;
}

@media screen and (min-width: 768px) {
  .glide__bullets {
    transform: unset;
    bottom: 50px;
    left: 30px;
  }
}

@media screen and (min-width: 1004px) {
  .glide__bullets {
    bottom: 60px;
    left: 65px;
  }
}

.glide__bullet {
  background-color: #6d6d6d;
  border-radius: 2px;
  width: 58px;
  height: 2px;
}

.glide__bullet--active {
  background-color: #00f3bc;
}

.team {
  padding: 0 20px;
}

@media screen and (min-width: 768px) {
  .team {
    padding: 0 30px;
  }
}

@media screen and (min-width: 1004px) {
  .team {
    max-width: 1110px;
    margin: 0 auto;
    padding: 0;
  }
}

.team__team {
  padding-top: 30px;
}

@media screen and (min-width: 768px) {
  .team__team {
    flex-wrap: wrap;
    gap: 30px;
    display: flex;
  }
}

@media screen and (min-width: 1004px) {
  .team__team {
    padding-top: 60px;
  }
}

.team-card_last {
  display: none;
}

.team-card_last .team-card__name {
  margin-bottom: 10px;
}

.team-card_last .team-card__position {
  font-size: 18px;
}

.team-card_last .header-cta {
  width: 100%;
}

@media screen and (min-width: 768px) {
  .team-card_last {
    background: #0d0d0d;
    border: 1px solid #222;
    border-radius: 20px;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    display: flex;
  }

  .team-card {
    width: calc(50% - 15px);
    position: relative;
  }
}

@media screen and (min-width: 1004px) {
  .team-card {
    width: calc(33.333% - 20px);
  }
}

.team-card + .team-card {
  margin-top: 30px;
}

@media screen and (min-width: 768px) {
  .team-card + .team-card {
    margin-top: 0;
  }

  .team-card__info {
    position: absolute;
    bottom: 30px;
    left: 30px;
  }
}

.team-card__img {
  border: 1px solid #222;
  border-radius: 10px;
  width: 100%;
  margin-bottom: 20px;
}

@media screen and (min-width: 768px) {
  .team-card__img {
    margin-bottom: 0;
  }
}

.team-card__name {
  color: #fff;
  letter-spacing: -1.2px;
  margin-bottom: 5px;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
  display: block;
}

.team-card__position {
  color: #c8c8c8;
  font-family: Manrope;
  font-size: 18px;
  line-height: 120%;
  display: block;
}

.values {
  padding: 100px 20px;
}

.values__inner {
  padding-top: 30px;
}

@media screen and (min-width: 768px) {
  .values__inner {
    flex-wrap: wrap;
    gap: 30px;
    display: flex;
  }
}

@media screen and (min-width: 1004px) {
  .values__inner {
    gap: 60px;
    padding-top: 60px;
  }
}

@media screen and (min-width: 768px) {
  .values {
    padding: 130px 30px;
  }
}

@media screen and (min-width: 1004px) {
  .values {
    max-width: 1110px;
    margin: 0 auto;
    padding: 200px 0;
  }
}

@media screen and (min-width: 768px) {
  .values-card {
    width: calc(50% - 15px);
  }
}

@media screen and (min-width: 1004px) {
  .values-card {
    width: calc(50% - 30px);
  }
}

.values-card + .values-card {
  margin-top: 30px;
}

@media screen and (min-width: 768px) {
  .values-card + .values-card {
    margin-top: unset;
  }
}

.values-card__title {
  color: #fff;
  letter-spacing: -1.2px;
  margin-bottom: 10px;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
}

@media screen and (min-width: 768px) {
  .values-card__title {
    font-size: 18px;
  }
}

@media screen and (min-width: 1004px) {
  .values-card__title {
    margin-bottom: 20px;
    font-size: 24px;
  }
}

.values-card__text {
  color: #c8c8c8;
  font-family: Manrope;
  font-size: 18px;
  line-height: 120%;
}

@media screen and (min-width: 768px) {
  .values-card__text {
    font-size: 16px;
  }
}

@media screen and (min-width: 1004px) {
  .values-card__text {
    font-size: 18px;
  }
}

.drive {
  margin: auto;
}

.drive-items-wrap {
  -webkit-user-select: none;
  user-select: none;
  background: #0d0d0d;
  border: 1px solid #222;
  gap: 40px;
  padding: 24px 0;
  display: flex;
  position: relative;
  overflow: hidden;
}

.drive-items-wrap:before, .drive-items-wrap:after {
  content: "";
  z-index: 1;
  pointer-events: none;
  width: 10%;
  height: 100%;
  position: absolute;
  top: 0;
}

.drive-items {
  counter-reset: item;
  flex-shrink: 0;
  justify-content: space-around;
  gap: 40px;
  min-width: 100%;
  display: flex;
}

.drive-item {
  color: #fff;
  text-transform: uppercase;
  font-family: Manrope;
  font-size: 18px;
  font-weight: 700;
  transition: all .1s ease-in-out;
}

.marquee {
  animation: 20s linear infinite scroll;
}

@keyframes scroll {
  from {
    transform: translateX(0);
  }

  to {
    transform: translateX(calc(-100% - 20px));
  }
}

.talents {
  overflow: hidden;
}

@media screen and (min-width: 768px) {
  .talents {
    margin-bottom: 130px;
    padding-left: 30px;
    display: flex;
    position: relative;
  }
}

@media screen and (min-width: 1004px) {
  .talents {
    margin-bottom: 300px;
    padding-left: 0;
  }
}

.talents__content {
  z-index: 10;
  padding: 60px 25px 0;
  position: relative;
}

@media screen and (min-width: 768px) {
  .talents__content {
    background: linear-gradient(90deg, #000 40%, #0000 100%);
    flex-shrink: 0;
    width: 335px;
    padding: 150px 0;
  }
}

@media screen and (min-width: 1004px) {
  .talents__content {
    width: 450px;
    margin-top: 50px;
    margin-left: calc(50% - 555px);
  }
}

.talents__caption {
  color: #fff;
  text-align: center;
  letter-spacing: -1.2px;
  margin-bottom: 20px;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
}

@media screen and (min-width: 768px) {
  .talents__caption {
    text-align: left;
  }
}

@media screen and (min-width: 1004px) {
  .talents__caption {
    letter-spacing: -1.8px;
    font-size: 36px;
  }
}

.talents__text {
  color: #c8c8c8;
  text-align: center;
  font-family: Manrope;
  font-size: 18px;
  line-height: 120%;
}

@media screen and (min-width: 768px) {
  .talents__text {
    text-align: left;
    font-size: 16px;
  }
}

@media screen and (min-width: 1004px) {
  .talents__text {
    font-size: 18px;
  }
}

.talents__btn {
  color: #c8c8c8;
  -webkit-text-decoration: unset;
  text-decoration: unset;
  background: #00000082;
  border: 1px solid #717171;
  border-radius: 33px;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 30px;
  padding: 20px 50px;
  font-family: Manrope;
  font-size: 18px;
  line-height: 24px;
  transition: all .3s ease-in-out;
  display: flex;
}

@media screen and (min-width: 768px) {
  .talents__btn {
    font-size: 16px;
  }
}

.talents__btn:hover {
  color: #000;
  background: #00f3bc;
  border: 1px solid #00f3bc;
}

.talents__video {
  opacity: .6;
  justify-content: center;
  display: flex;
  position: relative;
  transform: translateY(-100px);
}

.talents__video:before {
  content: "";
  background: linear-gradient(0deg, #0000 0%, #000 100%);
  width: 100%;
  height: 15%;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
}

.talents__video:after {
  content: "";
  background: linear-gradient(#0000 0%, #000 100%);
  width: 100%;
  height: 15%;
  display: block;
  position: absolute;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 768px) {
  .talents__video {
    transform: unset;
    position: absolute;
    top: 0;
    right: 0;
    transform: translateX(25%);
  }
}

@media screen and (min-width: 1004px) {
  .talents__video:after {
    height: 20%;
    bottom: 285px;
  }
}

.talents video {
  width: 150%;
}

@media screen and (min-width: 768px) {
  .talents video {
    width: 120%;
  }
}

@media screen and (min-width: 1004px) {
  .talents video {
    width: 100%;
  }
}

.footer {
  grid-template-columns: 1fr 1fr;
  width: 100%;
  padding: 0 20px;
  display: grid;
}

@media screen and (min-width: 768px) {
  .footer {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    column-gap: 90px;
    padding: 0 30px 60px;
  }
}

@media screen and (min-width: 1004px) {
  .footer {
    column-gap: unset;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    max-width: 1110px;
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
}

.footer__logo {
  grid-column: 1 / 3;
  margin-bottom: 60px;
  text-decoration: none;
  display: inline-block;
}

.footer__logo:visited {
  color: none;
}

.footer__title {
  color: #fff;
  text-align: center;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 500;
  line-height: 120%;
}

@media screen and (min-width: 768px) {
  .footer__title {
    text-align: left;
    font-size: 18px;
  }
}

.footer__cta {
  grid-column: 1 / 3;
  padding: 60px 0;
}

@media screen and (min-width: 768px) {
  .footer__cta {
    grid-row-start: 2;
    padding: 0;
  }
}

@media screen and (min-width: 1004px) {
  .footer__cta {
    grid-column-end: 5;
    align-items: center;
    gap: 30px;
    display: flex;
  }
}

.footer-cta {
  margin-top: 20px;
}

@media screen and (min-width: 1004px) {
  .footer-cta {
    margin-top: 0;
  }
}

.footer-nav {
  flex-wrap: wrap;
  display: flex;
}

.footer-nav__list {
  flex-direction: column;
  gap: 20px;
  list-style-type: none;
  display: flex;
}

@media screen and (min-width: 1004px) {
  .footer-nav__list_first {
    grid-column: 4 / 5;
  }

  .footer-nav__list_second {
    grid-column: 5 / 6;
  }
}

.footer-nav__list_contacts {
  grid-column: 1 / 3;
  margin-top: 30px;
}

@media screen and (min-width: 768px) {
  .footer-nav__list_contacts {
    grid-column: 3 / 5;
  }
}

@media screen and (min-width: 1004px) {
  .footer-nav__list_contacts {
    grid-column: 6 / 7;
    margin-top: 0;
  }
}

.footer-nav__caption {
  color: #fff;
  font-family: Manrope;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.footer-nav__link {
  color: #c8c8c8;
  font-family: Manrope;
  font-size: 18px;
  line-height: 120%;
  text-decoration: none;
}

.footer-nav__link:hover {
  color: #fff;
}

.copyright {
  color: #6d6d6d;
  text-align: center;
  border-top: 1px solid #222;
  padding: 30px 70px;
  font-family: Manrope;
  font-size: 16px;
  line-height: 120%;
}

@media screen and (min-width: 1004px) {
  .copyright br {
    display: none;
  }
}

/*# sourceMappingURL=index.28fafe7c.css.map */
